// Calendar

const cal = document.getElementById('date-pager-calendar');

if (cal !== null) {
  cal.addEventListener('change', (e) => {
    const originalUrl = window.location.href.split('?');
    const urlParams = originalUrl.length > 1 ? originalUrl[1].split('&') : [];
    let dateFound = false;
    for (let i = 0; i < urlParams.length; i += 1) {
      if (urlParams[i].split('=')[0] === 'date') {
        urlParams[i] = `date=${e.target.value}`;
        dateFound = true;
      }
    }
    if (dateFound === false) {
      urlParams.push(`date=${e.target.value}`);
    }
    window.location.href = `${originalUrl[0]}?${urlParams.join('&')}`;
  });
}
