const registerPulldownMenuChangeEvent = () => {
  const updateWillEdit = async (postId, adminUserId) => {
    const response = await fetch(`/posts/${postId}/will_edit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        admin_user_id: adminUserId,
      }),
    });
    const data = await response.text();

    return { response, data };
  };

  const eventHandler = (e) => {
    const element = e.target;
    e.preventDefault();
    e.stopPropagation();
    const postId = element.parentElement.id;
    const adminUserId = element.value;
    updateWillEdit(postId, adminUserId).then(({ response, data }) => {
      if (response.ok) {
        const linkText = document.getElementById(postId);
        linkText.parentElement.classList.add('done-background');
        linkText.parentElement.innerHTML = data;
      } else if ([409, 400].includes(response.status)) {
        const linkText = document.getElementById(postId);
        linkText.parentElement.classList.add('fail-background');
        linkText.parentElement.innerHTML = data;
      } else {
        throw new Error('Unexpected response.');
      }
    });
  };

  // select2のプルダウンメニューはjQueryのchangeイベントしか発行しない
  //   - https://github.com/select2/select2/issues/1908
  //   - https://github.com/select2/select2/issues/4686
  // 従って$el.changeを使っているが、select2を取り除いた際に$el.changeは消してquerySelectorAllとaddEventListenerで置き換えたい(jQuery依存排除のため)
  const $selector = $('.will-edit-link > select');
  $selector.change(eventHandler);
};

const registerWillEditClickEvent = () => {
  const willEditLink = document.getElementById('will-edit-link');
  if (willEditLink === null) {
    return;
  }

  const updateWillEdit = async () => {
    const response = await fetch(willEditLink.getAttribute('href'), {
      method: 'POST',
    });
    const data = await response.text();

    return { response, data };
  };

  const eventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updateWillEdit().then(({ response, data }) => {
      if (response.ok || [409, 400].includes(response.status)) {
        // responseがokでもエラーでも同じ処理を行う
        willEditLink.parentElement.innerHTML = data;
      } else {
        throw new Error('Unexpected response.');
      }
    });
  };

  willEditLink.addEventListener('click', eventHandler, { passive: false });
};

document.addEventListener('DOMContentLoaded', () => {
  registerPulldownMenuChangeEvent();
  registerWillEditClickEvent();
});
