const escapeHtml = (str) => {
  let escapedStr = str.replace(/&/g, '&amp;');
  escapedStr = escapedStr.replace(/>/g, '&gt;');
  escapedStr = escapedStr.replace(/</g, '&lt;');
  escapedStr = escapedStr.replace(/"/g, '&quot;');
  escapedStr = escapedStr.replace(/'/g, '&#x27;');
  escapedStr = escapedStr.replace(/`/g, '&#x60;');
  return escapedStr;
};

// Cloudflareの機能によって scriptタグをフォームで送信しようとするとエラーとなるため、HTMLエスケープを行う
const nativeFormatPostsSupplierButtonElement = document.getElementById(
  'native_format_posts_supplier_button'
);
if (nativeFormatPostsSupplierButtonElement) {
  nativeFormatPostsSupplierButtonElement.addEventListener('click', () => {
    const nativeFormatPostsSupplierScriptElement = document.getElementById(
      'native_format_posts_supplier_script'
    );

    if (nativeFormatPostsSupplierScriptElement) {
      const escapedScript = escapeHtml(
        nativeFormatPostsSupplierScriptElement.value
      );
      nativeFormatPostsSupplierScriptElement.value = escapedScript;
    }
  });
}
