const registerCopyButtonClickEvent = () => {
  const link = document.getElementById('copy-to-clipboard');

  if (link === null) {
    return;
  }

  const eventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    document.getElementById('copy-text').select();
    document.execCommand('copy');
  };

  link.addEventListener('click', eventHandler, { passive: false });
};

document.addEventListener('DOMContentLoaded', () => {
  registerCopyButtonClickEvent();
});
