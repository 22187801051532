/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import datetimepicker from 'eonasdan-bootstrap-datetimepicker';

$(() => {
  $('.form-datetimepicker').each((_i, el) => {
    const $el = $(el);
    const endOfTime = $el.data().dtpEndOfTime || false;
    const stepping = $el.data().stepping || 60;

    datetimepicker.apply($el, [
      {
        format: 'YYYY-MM-DD HH:mm',
        stepping,
      },
    ]);

    if (endOfTime) {
      $el.on('dp.change', (e) => {
        if (e.target.value.match(/00:00$/)) {
          const newDate = e.target.value.replace('00:00', '23:59');
          e.target.value = newDate;
        }
      });
    }
  });
});
