const registerSelectChangeEvent = () => {
  const handler = () => {
    const url = new URL('/posts/waiting_for_confirmation', document.baseURI);
    const params = url.searchParams;

    const [postType, postCategoryId, trainingStatus, assignee] = [
      document.getElementById('waiting-for-confirmation-post_type').value,
      document.getElementById('waiting-for-confirmation-post_category_id')
        .value,
      document.getElementById('waiting-for-confirmation-training_status').value,
      document.getElementById('waiting-for-confirmation-assignee').value,
    ];

    params.set('post_type', postType);
    params.set('post_category_id', postCategoryId);
    params.set('training_status', trainingStatus);
    params.set('assignee', assignee);

    window.location = url.toString();
  };

  document
    .querySelectorAll('.waiting-for-confirmation select')
    .forEach((element) => {
      element.addEventListener('change', handler);
    });
};

document.addEventListener('DOMContentLoaded', () => {
  registerSelectChangeEvent();
});
