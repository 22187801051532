const formElements = document.querySelectorAll('.feature-post-by-date');

formElements.forEach((element) => {
  element.addEventListener(
    'submit',
    (e) => {
      e.preventDefault();

      const form = e.currentTarget;
      const date = form.querySelector('.feature-post-by-date-feature-at').value;
      const time = form.querySelector('.feature-post-by-date-append-time')
        .textContent;

      form.querySelector(
        '.feature-post-by-date-feature-at'
      ).value = `${date} ${time}`;

      form.submit();
    },
    { once: true }
  );
});
