import Sortable from 'sortablejs'; // TODO: replace with react-dnd and remove this.

const registerSortable = () => {
  if (document.getElementById('sortable') === null) {
    return;
  }

  Sortable.create(document.querySelector('#sortable tbody'), {
    draggable: '.item',
    animation: 150,
    dragClass: 'dragging-item',
    onChoose(e) {
      e.item
        .querySelectorAll('td')
        .forEach((el) => el.classList.remove('highlight-short'));
    },
    onEnd(e) {
      e.item
        .querySelectorAll('td')
        .forEach((el) => el.classList.add('highlight-short'));
      document.querySelector('.update-order').classList.remove('btn-success');
      document.querySelector('.update-order').removeAttribute('disabled');
    },
  });
};

const registerButtonEvent = () => {
  const buttonElement = document.querySelector('.update-order');
  if (buttonElement === null) {
    return;
  }

  buttonElement.addEventListener('click', () => {
    buttonElement.setAttribute('disabled', true);
    const items = document.querySelectorAll('#sortable tr.item');
    const itemOrder = [].map.call(items, (obj) =>
      obj.getAttribute('data-item-id')
    );
    const requestPost = async () => {
      const response = await fetch(`${window.location.pathname}/reorder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ posts: itemOrder }),
      });
      return response;
    };
    requestPost()
      .then((response) => {
        if (response.ok) {
          document.querySelector('.update-order').classList.add('btn-success');
          return;
        }

        throw new Error();
      })
      .catch(() => {
        document.querySelector('.update-order').classList.add('btn-danger');
        document.querySelector('.update-order').removeAttribute('disabled');
      });
  });
};

registerSortable();
registerButtonEvent();
