const registerSubmitClickEvent = () => {
  const submit = document.querySelector(
    'input[type=submit].prevent-double-submit'
  );
  if (submit === null) {
    return;
  }

  const handler = () => {
    submit.classList.add('disabled');
  };
  submit.addEventListener('click', handler);
};

document.addEventListener('DOMContentLoaded', () => {
  registerSubmitClickEvent();
});
