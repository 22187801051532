import MarkJS from 'mark.js';

// highlight given keyword using markjs
const highlightKeyword = function (selector, keyword) {
  const markJS = new MarkJS(selector);
  markJS.mark(keyword, {
    className: 'highlighted',
  });
};

export default function (hiddenInputId, selector) {
  let splitKeyword;
  const hiddenField = document.getElementById(hiddenInputId);
  if (hiddenField === null) {
    return;
  }

  try {
    splitKeyword = JSON.parse(hiddenField.value);
  } catch (error) {
    splitKeyword = [hiddenField.value];
  }

  if (!Array.isArray(splitKeyword)) {
    return;
  }

  splitKeyword.forEach((keyword) => {
    highlightKeyword(selector, keyword);
  });
}
