/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import mermaid from 'mermaid';

document.addEventListener('DOMContentLoaded', () => {
  mermaid.initialize({ startOnLoad: true });
});
