import Sortable from 'sortablejs'; // TODO: replace with react-dnd and remove this.

const SORTABLE = '.sortable tbody';
const UPDATE_BTN = '.update-featured-order';

/**
 * Activate sortablejs.
 * Also see: admin/posts/update_order.js
 * @private
 * @param {NodeList} elements
 */
const activateSortable = (elements) => {
  elements.forEach((el) => {
    Sortable.create(el, {
      draggable: '.item',
      animation: 150,
      dragClass: 'dragging-item',
      onChoose(e) {
        e.item
          .querySelectorAll('td')
          .forEach((cellElement) =>
            cellElement.classList.remove('highlight-short')
          );
      },
      onEnd(e) {
        e.item
          .querySelectorAll('td')
          .forEach((cellElement) =>
            cellElement.classList.add('highlight-short')
          );
      },
    });
  });
};

/**
 * Bind button clicked event.
 * @private
 * @param {NodeList} elements
 */
const bindEvents = (elements) => {
  /**
   * Re-render updated view
   * And setup again
   * @private
   * @param {Object} resp
   */
  const rerenderView = (resp) => {
    const areaElement = document.getElementById(resp.id);
    areaElement.innerHTML = resp.el;

    activateSortable(areaElement.querySelectorAll(SORTABLE));
    bindEvents(areaElement.querySelectorAll(UPDATE_BTN));
  };

  /**
   * Send request to update order.
   * @private
   * @param  {Object} params
   * @return {Promise}
   */
  const request = async (params) => {
    const response = await fetch('/featured_posts/reorder', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    return { response, data };
  };

  elements.forEach((el) => {
    el.addEventListener('click', (e) => {
      const buttonElement = e.currentTarget;
      const tableElement = buttonElement.parentElement.nextElementSibling.nextElementSibling.querySelector(
        SORTABLE
      );

      buttonElement.classList.add('disabled');

      const ids = [...tableElement.querySelectorAll('.item')]
        .map((element) => element.dataset.itemId)
        .join(',');

      const params = {
        reorder: {
          ids,
        },
      };

      request(params)
        .then(({ response, data }) => {
          if (response.ok) {
            rerenderView(data);
            return;
          }
          throw new Error();
        })
        .catch((error) => {
          console.error(error); // eslint-disable-line no-console
        });
    });
  });
};

const setup = () => {
  const sortableElements = document.querySelectorAll(SORTABLE);

  if (!sortableElements.length) {
    return;
  }

  activateSortable(sortableElements);

  const buttonElements = document.querySelectorAll(UPDATE_BTN);
  bindEvents(buttonElements);
};

setup();
