import jQuery from 'jquery';

import 'jquery-ujs';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui-touch-punch';
import 'bootstrap';
import 'select2';
import 'select2/dist/js/i18n/ja';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';

import '../shared/raven_admin';
import '../shared/locari_twttr';

import '../admin';

window.jQuery = jQuery;
window.$ = jQuery;
