const linkElements = document.querySelectorAll('.no-reply-necessary');
const handler = (e) => {
  e.preventDefault();
  e.stopPropagation();

  const element = e.currentTarget;
  const confirmText = element.dataset.confirm;
  // eslint-disable-next-line no-alert, no-restricted-globals
  if (confirmText && !confirm(confirmText)) {
    return;
  }

  const updateFeedback = async () => {
    const response = await fetch(element.getAttribute('href'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  };

  updateFeedback().then((response) => {
    if (response.ok) {
      document.getElementById(element.dataset.id).remove();
    } else {
      throw new Error('Unexpected response.');
    }
  });
};

linkElements.forEach((element) => {
  element.addEventListener('click', handler);
});
