const registerButtonClickEvent = () => {
  const button = document.getElementById('show_all_post_deal_histories');
  if (button === null) {
    return;
  }

  const handler = () => {
    button.classList.add('disabled');
    document
      .querySelector('.post-deal-histories-table tr')
      .classList.remove('hide');
  };
  button.addEventListener('click', handler);
};

document.addEventListener('DOMContentLoaded', () => {
  registerButtonClickEvent();
});
