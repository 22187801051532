/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import '../shared/bootstrap-datepicker';

$(() => {
  $('.form-datepicker').datepicker({
    format: 'yyyy-mm-dd',
    weekStart: 1,
    language: 'ja',
    todayHighlight: true,
  });

  $('.set-one-month').click((e) => {
    const $currentInputGroup = $(e.currentTarget).parent().parent();
    return $currentInputGroup
      .find('.from-date')
      .val($('#one-month-date').text());
  });

  return $('.set-one-week').click((e) => {
    const $currentInputGroup = $(e.currentTarget).parent().parent();
    return $currentInputGroup
      .find('.from-date')
      .val($('#one-week-date').text());
  });
});
