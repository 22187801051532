const registerFormAmountChangeEvent = () => {
  const postCopyOutsourceFormAmount = document.getElementById(
    'post_copy_outsource_form_amount'
  );
  if (postCopyOutsourceFormAmount === null) {
    return;
  }

  const selectors = [
    '#post_copy_outsource_unit_cost',
    '#post_copy_outsource_unit_size',
    '#post_copy_outsource_adjustment',
  ];

  const handleChangeEvent = () => {
    const [unitCost, unitSize, adjustment] = selectors.map((id) =>
      parseInt(document.querySelector(id).value, 10)
    );

    postCopyOutsourceFormAmount.innerText = unitCost * unitSize + adjustment;
  };

  document.querySelectorAll(selectors.join(',')).forEach((element) => {
    element.addEventListener('change', handleChangeEvent);
  });
};

registerFormAmountChangeEvent();
