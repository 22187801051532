/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(() => {
  $.fn.select2.defaults.set('placeholder', '');
  $('.form-select2').select2({
    theme: 'bootstrap',
    width: '100%',
    allowClear: true,
  });
});
